import { importProvidersFrom } from '@angular/core';
import { environment } from '../environments/environment';
import { TrackerModule } from '@origin8-web/core-ui/tracking';
import { APP_CONFIG, localStorageProvider, windowProvider } from '@origin8-web/core-ui/shared-providers';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
} from '@angular/router';
import { routes } from './app.routes';
import { LpClientEnv } from '../environments';

export const appConfig = {
  providers: [
    importProvidersFrom(TrackerModule.forRoot({ anonymous: true, withGtm: true })),
    localStorageProvider,
    windowProvider,
    provideHttpClient(withFetch()),
    provideAnimations(),
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withComponentInputBinding(),
    ),
  ],
};
export const getAppConfig = (envVariables: LpClientEnv) => {
  return { ...appConfig, providers: [...appConfig.providers, { provide: APP_CONFIG, useValue: envVariables }] };
};
