import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { getAppConfig } from './app/app.config';
import { environment } from './environments/environment';

export const DEFAULT_CONFIG_LOADER_PATH = '/assets/config/config.json';

const headers = { 'Cache-Control': 'no-cache' };
fetch(DEFAULT_CONFIG_LOADER_PATH, { headers })
  .then((response) => response.json())
  .then((confFromFile) => confFromFile)
  .catch(() => {
    console.warn('Error while loading config file, using default config');
    return environment;
  })
  .then((loadedConfig) => {
    bootstrapApplication(AppComponent, getAppConfig(loadedConfig)).catch((err) => console.error(err));
  });
