// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { InsuranceProvider, Product } from 'common.interfaces/global';

export const environment = {
  production: true /* Do not change*/,
  pathToSource: 'browser',
  anonymousGatewayApi: 'https://gateway-public.dev.o8cares.com/api',
  websiteUrl: 'https://web.uat.origin8cares.com/',
  trackingId: 'bvowph/landing-pages',
  cnoSsUrl: 'https://www.colonialpenn.com/quote/-/form/submit',
  calendlyUrls: {
    [InsuranceProvider.COL_PEN]: {
      [Product.FINAL]: {
        o8: 'https://calendly.com/cno-gbl-test',
      },
    },
    [InsuranceProvider.PGL]: {
      [Product.TERM_19]: {
        o8: 'https://calendly.com/pgl-term/pgl-term-test',
      },
    },
    [InsuranceProvider.NATIONWIDE]: {
      [Product.TERM_19]: {
        o8: 'https://calendly.com/nationwide-term/nationwide-term-test?utm_source=email&utm_medium=lp&utm_campaign=thankyou',
      },
    },
    [InsuranceProvider.WASHINGTON_NATIONAL]: {
      [Product.CRITICAL_ILLNESS]: {
        o8: 'https://calendly.com/wn-criticalinllness/washington-national-critical-illness-insurance-test?utm_source=email&utm_medium=lp&utm_campaign=thankyou',
      },
    },
    [InsuranceProvider.FG]: {
      [Product.IUL]: {
        o8: 'https://calendly.com/f-g-iul/fg-iul-test?utm_source=lp&utm_medium=simple_form&utm_campaign=main_page',
      },
      [Product.MYGA]: {
        o8: 'https://calendly.com/fg-myga-test',
      },
    },
    [InsuranceProvider.HAVEN_LIFE]: {
      [Product.TERM_SIMPLE]: {
        o8: 'https://calendly.com/hl-term-test/haven-life?utm_source=lp&utm_medium=quote_page',
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
