import { Routes } from '@angular/router';
import { WhiteLabelPartner } from 'common.interfaces';
import { THANK_YOU_ROUTE } from '../features/thank-you/thank-you.interface';

export const routes: Routes = [
  {
    path: 'colpenn-final-expense',
    loadComponent: () =>
      import(
        /* webpackChunkName: "col-penn-final-expense" */ '../features/lp-pages/col-penn-final-expense/col-penn-final-expense.component'
      ).then((c) => c.ColPennFinalExpenseComponent),
  },
  {
    path: 'colpenn-final-expense-with-quote',
    loadComponent: () =>
      import(
        /* webpackChunkName: "col-penn-final-expense-with-quote" */ '../features/lp-pages/col-penn-final-expense-with-quote/col-penn-final-expense-with-quote.component'
      ).then((c) => c.ColPennFinalExpenseWithQuoteComponent),
  },
  {
    path: 'nationwide-term',
    loadComponent: () =>
      import(
        /* webpackChunkName: "nationwide-term" */ '../features/lp-pages/natiowide-term/nationwide-term.component'
      ).then((c) => c.NationwideTermComponent),
  },
  {
    path: 'washington-national-critical-illness-standard',
    loadComponent: () =>
      import(
        /* webpackChunkName: "washington-national-critical-illness-standard" */ '../features/lp-pages/washington-national-critical-illness-standard/washington-national-critical-illness-standard.component'
      ).then((c) => c.WashingtonNationalCriticalIllnessStandardComponent),
  },
  {
    path: 'fg-iul',
    loadComponent: () =>
      import(/* webpackChunkName: "fg-iul-term" */ '../features/lp-pages/f&g-iul/f&g-iul.component').then(
        (c) => c.FgIulComponent,
      ),
  },
  {
    path: 'fg-myga',
    loadComponent: () =>
      import(/* webpackChunkName: "fg-myga-term" */ '../features/lp-pages/f&g-myga/f&g-myga.component').then(
        (c) => c.FGMygaComponent,
      ),
  },
  // {
  //   path: 'pgl-term-with-quote',
  //   loadComponent: () =>
  //     import(
  //       /* webpackChunkName: "pgl-term-with-quote" */ '../features/lp-pages/pgl-term-with-quote/pgl-term-with-quote.component'
  //     ).then((c) => c.PglTermWithQuoteComponent),
  // },
  // {
  //   path: 'pgl-term-standard',
  //   loadComponent: () =>
  //     import(
  //       /* webpackChunkName: "pgl-term-standard" */ '../features/lp-pages/pgl-term-standard/pgl-term-standard.component'
  //     ).then((c) => c.PglTermStandardComponent),
  // },
  {
    path: 'pgl-tdi',
    data: { whiteLabelPartner: WhiteLabelPartner.PGL },
    loadComponent: () =>
      import(/* webpackChunkName: "pgl-tdi" */ '../features/lp-pages/pgl-tdi/pgl-tdi.component').then(
        (c) => c.PglTdiComponent,
      ),
  },
  {
    path: 'assurity-disability-income',
    loadComponent: () =>
      import(
        /* webpackChunkName: "assurity-disability-income" */ '../features/lp-pages/assurity-disability-income/assurity-disability-income.component'
      ).then((c) => c.AssurityDisabilityIncomeComponent),
  },
  {
    path: 'legal-and-general-term',
    loadComponent: () =>
      import(
        /* webpackChunkName: "legal-and-general-term" */ '../features/lp-pages/legal-and-general-term/legal-and-general-term.component'
      ).then((c) => c.LegalAndGeneralTermComponent),
  },
  {
    path: THANK_YOU_ROUTE.BASE,
    loadComponent: () =>
      import(/* webpackChunkName: "thank-you-page" */ '../features/thank-you/thank-you.component').then(
        (c) => c.ThankYouPageComponent,
      ),
  },
];
