import { Component, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppShellNoRenderDirective } from '@origin8-web/core-ui/shell';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lp-client',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [AppShellNoRenderDirective, RouterOutlet],
})
export class AppComponent {
  title = 'lp-client';

  constructor() {}
}
